
export const testEmail = (email) => {

 
    if (!email)
        return (null)
    const split1 = email.split('@')
    const split2 = (split1.length > 1) ? split1[1].split('.') : null

    if (split2 && split2[1])
        return (true)
    else
        return (false)

}

export const testNoSpace = (email) =>{


    if (!email)
    return (false)

    if (email.includes(' '))
        return false
    return true
}

export const testEmailLength = (email) =>{

    if (!email)
    return (false)


    if (email.length > 7)
        return true
    return false
}



export const testEmailLower = (email) =>{

    if (!email)
    return (false)


    if  (/[a-z]/.test(email)) 
        return true
    return false
}

export const testEmailUpper = (email) =>{

    if (!email)
    return (false)


    if  (/[A-Z]/.test(email))
        return true
    return false
}



