import React, { useEffect, useState } from 'react'
import "../style/css/style.min.css"
import "aos/dist/aos.css"


import { green } from '@mui/material/colors'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Layout from "../components/layout"
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { handleNewMail } from '../helpers/fetchers'
import { testEmail } from '../helpers/useful'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome'
import CheckIcon from '@mui/icons-material/Check';
import FrontOfficeTheme from '../style/globalTheme'






const imagePrefix = "https://www.pekoia.com/static/"
const baseUrl = "https://www.pekoia.com"




const About = ({ setOpen, onStateChange, authState }) => {

    const discoverButtonHandler = () => { }
    const [textInput, setTextInput] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)
    const [showInput, setShowInput] = useState(false)

    // const handleValidate = (event) => {}

    const handleValidate = (event) => {


        console.log('@HANDLE')
        // event.preventDefault()

        setShowSpinner(true)
        handleNewMail(textInput)


        setTimeout(() => {
            setTextInput('')
            setShowInput(!showInput)
            setShowSpinner(false)
        }, 1000)

    }

    let AOS;
    useEffect(() => {
       
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, []);

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });





    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={FrontOfficeTheme}>
            <Layout >
                {/* Call to action 32 */}
                <section className="pt-105 pb-45 bg-light text-center feature_1">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h2 className="mb-45 small color-main" data-aos="zoom-in" data-aos-delay="0">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Qu'est-ce que Pekoia?
                                        </font>
                                    </font>
                                </h2>
                                <div className="row justify-content-center">
                                    <div className="col-md-4 mb-50" data-aos="zoom-in" data-aos-delay="250">
                                        <div className="mt-20 mb-25 title f-20 bold">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    Une université en ligne ouverte à tous
                                                </font>
                                            </font>
                                        </div>
                                        <img src={imagePrefix + "hand-0feca39c01c9c57bdf39866220beb68f.jpg"} alt="Quelques textes sur cette image" className="w-220" />
                                        <div className="text-adaptive my-3 color-bg-dark">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    Pekoia est une université en ligne au service du plaisir de découverte et de
                                                    transmission ...
                                                </font>
                                            </font>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-50" data-aos="zoom-in" data-aos-delay="500">
                                        <div className="mt-20 mb-25 title f-20 bold">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    Des événements à distance en direct
                                                </font>
                                            </font>
                                        </div>
                                        <img src={imagePrefix + "lady-fa9fd92f4397f965a37a1c09fd599819.jpg"} alt="Quelques textes sur cette image"
                                            className="w-220" />
                                        <div className="text-adaptive my-3 color-bg-dark">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    ...qui permet aux intervenants et participants de se rencontrer lors
                                                    d'événements à distance en direct pour plus d'interactivité ...
                                                </font>
                                            </font>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-50" data-aos="zoom-in" data-aos-delay="750">
                                        <div className="mt-20 mb-25 title f-20 bold">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    Des formats de conférences à taille humaine
                                                </font>
                                            </font>
                                        </div>
                                        <img src={imagePrefix + "multi-035ab7dce6f0160b0170ac97ae44dc49.jpg"} alt="Quelques textes sur cette image"
                                            className="w-220" />
                                        <div className="text-adaptive my-3 color-bg-dark">
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    ...dans des formats de conférences ou d'ateliers à taille humaine pour plus de
                                                    convivialité.
                                                </font>
                                            </font>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Form 8 --> */}
                <section className="pt-105 pb-100 bg-dark text-center color-white color-filter-dark-4 form_8" data-bg-src="false"
                    data-bg-src-2x="false">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <h2 className="mb-25 small" data-aos="zoom-in" data-aos-delay="0" data-bg-src="uploads/fondnoir.png">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            S'inscrire à la newsletter
                                        </font>
                                    </font>
                                </h2>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-10" data-aos="zoom-in" data-aos-delay="250">
                                <div className="text-adaptive f-16">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            La meilleure façon de se tenir au courant de l'agenda des conférences est de s'abonner à
                                            notre newsletter hebdomadaire :
                                        </font>
                                    </font>
                                </div>
                            </div>
                        </div>
                        <div className="mt-50 mb-30">
                            <div className="row justify-content-center">
                                <div className="col-xl-3 col-md-4 col-sm-8" data-aos="zoom-in" data-aos-delay="500">
                                </div>
                                <div className="col-md-5 col-sm-8" data-aos="zoom-in" data-aos-delay="500">
                                    <input
                                    type="email"
                                    value={textInput}
                                    onChange={(e) => {
                                        setTextInput(e.target.value);
                                    }}
                                    name="email"
                                    placeholder="Votre adresse email"
                                    required="required"
                                    className="input mb-30 w-full lg border-transparent-white focus-white color-white placeholder-transparent-white text-center text-md-left" />
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-8" data-aos="zoom-in" data-aos-delay="750">
                                    <button className={testEmail(textInput) ? "btn lg w-full action-red" : "btn lg w-full action-gray"} onClick={()=>{testEmail(textInput) && handleValidate()}}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Recevoir
                                            </font>
                                            {showSpinner && <CircularProgress size={25} />}
                                        </font>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-adaptive" data-aos="zoom-in" data-aos-delay="1000">
                        </div>
                    </div>
                </section>
                {/* <!-- Blog 24 --> */}
                <section className="pt-50 pb-50 blog_24 bg-gray" data-bg-src="false">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8 d-sm-flex justify-content-between">
                                <h4 className="mb-0 f-32 text-center text-sm-left color-main" data-aos="zoom-in" data-aos-delay="0">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Suivez-nous également sur
                                        </font>
                                    </font>
                                </h4>
                                <div className="mt-25 mt-sm-0 d-flex justify-content-center justify-content-sm-end align-items-center">
                                    <a target="_blank" href="https://www.facebook.com/Pekoia-101133162116892"
                                        className="mr-15 h-40 w-40 color-white f-18 lh-40 text-center radius10 link"
                                        style={{ backgroundColor: "#4267B2" }} data-aos="zoom-in" data-aos-delay="250">
                                        <FontAwesomeIcon icon={["fab", "facebook"]} />

                                    </a>

                                    <a target="_blank" href="https://www.instagram.com/pekoia_/" className="mr-15 h-40 w-40 color-white f-18 lh-40 text-center radius10 link"
                                        style={{ backgroundColor: "#fb3958" }} data-aos="zoom-in" data-aos-delay="500">
                                        <FontAwesomeIcon icon={["fab", "instagram"]} />

                                    </a>

                                    {/* <a href="#" className="mr-15 h-40 w-40 color-white f-18 lh-40 text-center radius10 link"
                                        style={{ backgroundColor: "#0077B5" }} data-aos="zoom-in" data-aos-delay="750">
                                        <FontAwesomeIcon icon={["fab", "linkedin"]} />

                                    </a> */}

                                    <a target="_blank" href="https://twitter.com/Pekoia" className="h-40 w-40 color-white f-18 lh-40 text-center radius10 link"
                                        style={{ backgroundColor: "#1DA1F2" }} data-aos="zoom-in" data-aos-delay="1000">
                                        <FontAwesomeIcon icon={["fab", "twitter"]} />

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Showcase 2 --> */}
                <section className="pt-105 pb-90 text-center showcase_2 bg-dark">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <h2 className="small color-white" data-aos="zoom-in" data-aos-delay="0">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Les thématiques
                                        </font>
                                    </font>
                                </h2>
                                <div className="mt-15 mb-25 text-adaptive color-white f-18" data-aos="zoom-in" data-aos-delay="250">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            L'offre sur Pekoia est à l'initiative de ceux qui veulent proposer des interventions.{" "}
                                        </font>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Pekoia hébergeur, classe, met en avant et présente sous format agenda cette offre.{" "}
                                        </font>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Les conférences sont notamment classées selon une ou plusieurs thématiques.
                                        </font>
                                    </font>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6" data-aos="zoom-in" data-aos-delay="0">
                                <a   className="mt-50 link img_link color-bg-light">
                                    <img src={imagePrefix + "photo-arts-daf85a0b35c743b97414db09101e819e.jpg"} alt=""
                                        className="img-fluid radius10 w-340 h-220" />
                                    <div className="mt-20 mb-15 f-22 title">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Arts
                                            </font>
                                        </font>
                                    </div>
                                    <div className="color-heading f-14 semibold text-uppercase sp-20">
                                    </div>
                                </a>

                            </div>
                       
                            <div className="col-md-6 col-sm-6" data-aos="zoom-in" data-aos-delay="500">
                                <a  className="mt-50 link img_link color-bg-light">
                                    <img src={imagePrefix + "photo-environnement-5d85854df83ce005c4f5fce4cb454e96.jpg"} alt="" className="img-fluid radius10 w-340 h-220" />
                                    <div className="mt-20 mb-15 f-22 title">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                            Sciences &amp; Planète
                                            </font>
                                        </font>
                                    </div>
                                    <div className="color-heading f-14 semibold text-uppercase sp-20">
                                    </div>
                                </a>
                            </div>
                   
                            <div className="col-md-6 col-sm-6" data-aos="zoom-in" data-aos-delay="250">
                                <a className="mt-50 link img_link color-bg-light">
                                    <img src={imagePrefix + "photo-éducation-8167de97ea3c86a308e479e1116e7050.jpg"} alt="" className="img-fluid radius10 w-340 h-220" />
                                    <div className="mt-20 mb-15 f-22 title">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Vie personnelle
                                            </font>
                                        </font>
                                    </div>
                                    <div className="color-heading f-14 semibold text-uppercase sp-20">
                                    </div>
                                </a>
                                
                         
                            </div>
                            <div className="col-md-6 col-sm-6" data-aos="zoom-in" data-aos-delay="500">
                                <a className="mt-50 link img_link color-bg-light">
                                    <img src={imagePrefix + "photo-histoire&géopolitique-108c2c902c60b9891944b87e814cd1b0.jpg"} alt="" className="img-fluid radius10 w-340 h-220" />
                                    <div className="mt-20 mb-15 f-22 title">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Histoire et société
                                            </font>
                                        </font>
                                    </div>
                                    <div className="color-heading f-14 semibold text-uppercase sp-20">
                                    </div>
                                </a>
                           
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-105 bg-light text-center feature_3 pb-10">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-10">
                                <h2 className="small" data-aos="zoom-in" data-aos-delay="0">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Les formats des événements
                                        </font>
                                    </font>
                                </h2>
                                <div className="mt-25 mb-65 f-22 color-heading op-7 text-adaptive description" data-aos="zoom-in"
                                    data-aos-delay="250">
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center align-items-stretch">
                            <div className="col-lg-4 col-md-8 col-sm-10 d-flex align-items-stretch">
                                <div className="px-45 pt-50 pb-60 radius10 mb-30 block" data-aos="zoom-in" data-aos-delay="500">
                                    <img src={imagePrefix + "confrenceclassedistance-74b4634046f54b0b936639e44bf0ea02.png"} alt="Quelques textes sur cette image" />
                                    <div className="mt-20 mb-20 f-22 title bold">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Cours à distance
                                            </font>
                                        </font>
                                    </div>
                                    <div className="op-7 text-adaptive color-bg-dark f-16">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Les conférences en format classe à distance ne peuvent dépasser 50 participants et
                                                ont pour objectif d'offrir un maximum d'interactivité entre l'intervenant et les
                                                participants voire entre les participants entre eux.{" "}
                                            </font>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Elles sont dispensées sur des outils comme Zoom, Teams ou Google Meet.
                                            </font>
                                        </font>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-10 d-flex align-items-stretch">
                                <div className="px-45 pt-50 pb-60 radius10 mb-30 block" data-aos="zoom-in" data-aos-delay="750">
                                    <img src={imagePrefix + "webinar-d81b29a8da0792e73996240c5f169394.png"} alt="Quelques textes sur cette image" />
                                    <div className="mt-20 mb-20 f-22 title bold">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Webinaires
                                            </font>
                                        </font>
                                    </div>
                                    <div className="op-7 text-adaptive color-bg-dark">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Les webinaires ou webinaires sur Pekoia sont des conférences à distance dont la
                                                capacité est supérieure à 50 participants.{" "}
                                            </font>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Elles ont pour objectif de partager l'intervention d'un expert en direct avec un
                                                nombre plus élevé de participants qui peuvent poser des questions généralement à
                                                l'aide de l'outil de chat.
                                            </font>
                                        </font>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-10 d-flex align-items-stretch">
                                <div className="px-45 pt-50 pb-60 radius10 mb-30 block" data-aos="zoom-in" data-aos-delay="1000">
                                    <img src={imagePrefix + "atelier-771332d1153146e83d0cbbc9de367c25.png"} alt="Quelques textes sur cette image" />
                                    <div className="mt-20 mb-20 f-22 title bold">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Ateliers à distance
                                            </font>
                                        </font>
                                    </div>
                                    <div className="op-7 text-adaptive color-bg-dark">
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Les ateliers à distance sur Pekoia sont des rencontres entre les participants
                                                (nombre précisé sur la page de l'atelier) et un intervenant dont l'enseignement
                                                nécessitera une action d'apprentissage.{" "}
                                            </font>
                                            <font style={{ verticalAlign: 'inherit' }}>
                                                Ces ateliers ont pour objectif d'offrir un maximum d'interactivité entre
                                                l'intervenant et les participants et de stimuler l'apprentissage par l'action et la
                                                réalisation.
                                            </font>
                                        </font>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Blog 27 --> */}
                <section className="bg-light blog_27 pt-10 pb-10">
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <h6 className="mb-25 color-heading f-14 text-uppercase sp-20" data-aos="zoom-in" data-aos-delay="0">
                                </h6>
                                <div className="mb-40 row justify-content-between" data-aos="zoom-in" data-aos-delay="250">
                                    <div className="col-md-5 col-xl-6">
                                        <a href="#" className="link img_link" data-bg-src="uploads/cycle.png">
                                            <img src={imagePrefix + "cycle-adf675e914fde599d7d4f93dbdb00436.png"} alt="" className="mb-25 mb-md-0 img-fluid radius10" />
                                        </a>
                                    </div>
                                    <div className="pl-xl-0 col-md-7 col-xl-6 d-flex flex-column justify-content-between">
                                        <div>
                                            <a href="#" className="mb-25 color-main f-32 bold link">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Les cycles
                                                    </font>
                                                </font>
                                            </a>
                                            <div className="color-heading f-16 text-adaptive mb-0">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        A noter&nbsp;:&nbsp;Certains événements peuvent être présentés de manière
                                                        autonome (vous inscrivez à telle ou telle conférence)
                                                    </font>
                                                </font>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-wrap justify-content-between align-items-center color-heading f-14 bold text-uppercase sp-20">
                                            <a href="#" className="color-heading link">
                                            </a>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                </div>
                                                <span className="ml-10 mr-10">
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        <font style={{ verticalAlign: 'inherit' }}>
                                                            ·
                                                        </font>
                                                    </font>
                                                </span>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-40 col-12 px-lg-0 w-full hr h-2 bg-gray" data-aos="zoom-in" data-aos-delay="500">
                                </div>
                                <div className="mb-40 row justify-content-between" data-aos="zoom-in" data-aos-delay="250">
                                    <div className="col-md-5 col-xl-6">
                                        <a href="#" className="link img_link">
                                            <img src={imagePrefix + "replay-d4f78cad99e65aad82be7cc1d2feb6eb.png"} alt="" className="mb-25 mb-md-0 img-fluid radius10" />
                                        </a>
                                    </div>
                                    <div className="pl-xl-0 col-md-7 col-xl-6 d-flex flex-column justify-content-between">
                                        <div>
                                            <a href="#" className="mb-25 color-main f-32 bold link">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Les replays
                                                    </font>
                                                </font>
                                            </a>
                                            <div className="mb-25 color-heading f-16 text-adaptive">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Certains intervenants peuvent décider de proposer leurs conférences
                                                        également en replay.
                                                    </font>
                                                </font>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-wrap justify-content-between align-items-center color-heading f-14 bold text-uppercase sp-20">
                                            <a href="#" className="color-heading link">
                                            </a>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                </div>
                                                <span className="ml-10 mr-10">
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        <font style={{ verticalAlign: 'inherit' }}>
                                                            ·
                                                        </font>
                                                    </font>
                                                </span>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="pt-100 pb-100 blog_3 bg-dark">
                    <h2 className="text-center f-38 color-white">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>
                                A qui s'adresse Pekoia ?
                            </font>
                        </font>
                    </h2>
                    <div className="container px-xl-0">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="row justify-content-between flex-column flex-md-row pb-45">
                                    <div className="col-md-6 col-xl-5 d-flex flex-column-reverse flex-xl-column justify-content-end justify-content-xl-between order-1 order-md-0"
                                        data-aos="zoom-in" data-aos-delay="250">
                                        <div>
                                            <h3 className="mb-15 f-32 text-adaptive my-3 px-5 my-5 color-bg-light">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Les intervenants
                                                    </font>
                                                </font>
                                            </h3>
                                            <div className="f-18 op-8 text-adaptive color-white">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Tous ceux qui ont du plaisir à transmettre leur compétence / expertise sont
                                                        invités à proposer leurs conférences sur Pekoia.{" "}
                                                    </font>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Nous avons prévu que ce principe d'ouverture sera bénéfique à la fois pour
                                                        des conférenciers déjà établis dont les interventions seront attendues un
                                                        public plus large et à la fois pour des experts ou passionnés qui n'avaient
                                                        pas encore une voix au chapitre et dont les interventions sont importantes
                                                        d'être plus largement diffusés.
                                                    </font>
                                                </font>
                                            </div>
                                            <div className="f-18 op-8 text-adaptive color-white mt-4">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Nous prenons en compte certaines mesures dans un souci de transparence et de
                                                        qualité en nous assurant au travers d'enquêtes notamment&nbsp;:
                                                    </font>
                                                </font>
                                            </div>
                                            <div className="f-18 op-8 text-adaptive color-white mt-4">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        - que chaque participant peut s'inscrire en ayant une bonne appréciation des
                                                        compétences de l'intervenant.{" "}
                                                    </font>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        C'est pour cette raison que chaque intervenant sera noté par les
                                                        participants.
                                                    </font>
                                                </font>
                                            </div>
                                            <div className="f-18 op-8 text-adaptive color-white mt-4">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        - que les conférences se déroulent dans un esprit positif, bienveillant et
                                                        ouvert qui permette à tous d'apprendre pour le plaisir.
                                                    </font>
                                                </font>
                                            </div>
                                            <div className="f-18 op-8 text-adaptive color-white mt-4">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        A travers une approche algorithmique, nous mettrons en avant sur la page
                                                        d'accueil des conférences organisées par les intervenants nous présentons
                                                        les meilleures garanties de satisfaction et d'expertise.
                                                    </font>
                                                </font>
                                            </div>
                                            <a href="#" className="mt-60 btn f-16 sm action-1">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Transmettre
                                                    </font>
                                                </font>
                                            </a>
                                        </div>
                                        <div className="mb-10 mb-xl-0 action-2 f-14 bold text-uppercase sp-20">
                                        </div>
                                    </div>
                                    <div className="mb-30 mb-md-0 col-md-6 col-sm-8 col-10 order-0 order-md-1" data-aos="zoom-in"
                                        data-aos-delay="0">
                                        <div className="relative">
                                            <img src={imagePrefix + "intervenant-7708cf4b2b4d105548c94c56dac147e8.jpg"} className="w-full h-full radius10 my-5" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-between pt-45">
                                    <div className="mb-30 mb-md-0 col-md-6 col-sm-8 col-10" data-aos="zoom-in" data-aos-delay="0">
                                        <div className="relative">
                                            <img src={imagePrefix + "apprenant-180000763575da603652afdfacdb5e44.jpg"} className="w-full h-full radius10" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-5 d-flex flex-column-reverse flex-xl-column justify-content-end justify-content-xl-between"
                                        data-aos="zoom-in" data-aos-delay="250">
                                        <div>
                                            <h3 className="mb-15 f-32 text-adaptive mx-auto px-5 color-bg-light">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Les participants
                                                    </font>
                                                </font>
                                            </h3>
                                            <div className="f-18 op-8 text-adaptive color-white">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Tous ceux qui souhaitent apprendre pour le plaisir, sans recherche de
                                                        certification ou de diplôme.{" "}
                                                    </font>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Les personnes morales (entreprises, associations…) sont également les
                                                        bienvenues et peuvent s'inscrire en tant que participant à des conférences.{" "}
                                                    </font>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Il est aussi possible pour des groupes de particuliers ou des personnes
                                                        morales de prendre contact avec les intervenants pour leur demande une
                                                        intervention à titre privé (intervention au sein d'une entreprise, pour un
                                                        CE, au sein d'une association …). Avant de vous inscrire à une conférence,
                                                        nous vous informons sur l'intervenant à travers lequel il peut partager ses
                                                        expertises, son parcours et ses publications.
                                                    </font>
                                                </font>
                                            </div>
                                            <a href="#" className="mt-60 btn f-16 sm action-1">
                                                <font style={{ verticalAlign: 'inherit' }}>
                                                    <font style={{ verticalAlign: 'inherit' }}>
                                                        Apprendre
                                                    </font>
                                                </font>
                                            </a>
                                        </div>
                                        <div className="mb-10 mb-xl-0 action-2 f-14 bold text-uppercase sp-20">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pt-105 pb-100 bg-light text-center team_4">
                    <div className="container px-xl-0">
                        <h2 className="mb-15 small" data-aos="zoom-in" data-aos-delay="0">
                            <font style={{ verticalAlign: 'inherit' }}>
                                <font style={{ verticalAlign: 'inherit' }}>
                                    Notre équipe
                                </font>
                            </font>
                        </h2>
                        <div className="row justify-content-center">
                            <div className="mt-70 col-lg-4 col-sm-6" data-aos="zoom-in" data-aos-delay="0">
                                <img src={imagePrefix + "photoad4-d2729afb1a3b518d4b9d6150773593b6.jpg"} alt="" className="radius6 img-fluid w-220 h-280" />
                                <div className="mt-20 mb-10 f-22 title">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Antoine Delorme
                                        </font>
                                    </font>
                                </div>
                                <div className="f-14 semibold text-uppercase sp-20 action-1">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Co-fondateur
                                        </font>
                                    </font>
                                </div>
                                <div className="mt-10 color-heading text-adaptive">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Antoine Delorme a fait sa carrière en finance des marchés.{" "}
                                        </font>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Il est diplômé de Centrale Paris et ancien élève de l'Ecole 42. Passionné par les
                                            nouvelles technologies et les modifications qu'elles peuvent apporter à la société,
                                            notamment dans le secteur de l'éducation.
                                        </font>
                                    </font>
                                </div>
                            </div>
                            <div className="mt-70 col-lg-4 col-sm-6" data-aos="zoom-in" data-aos-delay="250">
                                <img src={imagePrefix + "photopmv4-f90c52eecbd16c8ad861260a2c7f8129.jpg"} alt="" className="radius6 img-fluid w-220 h-280" />
                                <div className="mt-20 mb-10 f-22 title">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Patrice Mouly
                                        </font>
                                    </font>
                                </div>
                                <div className="f-14 semibold text-uppercase sp-20 action-1">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Co-fondateur
                                        </font>
                                    </font>
                                </div>
                                <div className="mt-10 color-heading text-adaptive">
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Patrice Mouly était responsable de la formation des marques de PSA en France.{" "}
                                        </font>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Il est diplômé de Neoma et d'un executive master de finance de Sciences Po Paris.{" "}
                                        </font>
                                        <font style={{ verticalAlign: 'inherit' }}>
                                            Amateur d'histoire de l'art, de sport et de musique.
                                        </font>
                                    </font>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" data-aos-delay="250">
                            <a href="mailto:contact@pekoia.com" className="btn mt-60 size50 action-bg-dark">
                                <font style={{ verticalAlign: 'inherit' }}>
                                    <font style={{ verticalAlign: 'inherit' }}>
                                        Nous contacter
                                    </font>
                                </font>
                            </a>
                        </div>
                    </div>
                </section>
              
            </Layout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
export default About
